import pokemonApi from '../api/pokemonApi'

export function getPokemons () {
  const getPokemonsArr = Array.from(Array(650))
  return getPokemonsArr.map((_, index) => index + 1)
}

export default async function getPokemonOptions () {
  const mixedPokemons = getPokemons().sort(() => Math.random() - 0.5)
  return await pokemonName(mixedPokemons.splice(0, 4))
}

export async function pokemonName ([a, b, c, d]) {
  const promiseArr = [
    pokemonApi.get(`/${a}`),
    pokemonApi.get(`/${b}`),
    pokemonApi.get(`/${c}`),
    pokemonApi.get(`/${d}`)
  ]

  const [p1, p2, p3, p4] = await Promise.all(promiseArr)

  return [
    { name: p1.data.name, id: p1.data.id },
    { name: p2.data.name, id: p2.data.id },
    { name: p3.data.name, id: p3.data.id },
    { name: p4.data.name, id: p4.data.id }
  ]
}
